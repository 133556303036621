@tailwind base;
@tailwind components;
@tailwind utilities;

.tp-stars--5 .tp-star:nth-of-type(-n+5) .tp-star__canvas, .tp-stars--5 .tp-star:nth-of-type(-n+5) .tp-star__canvas--half {
  fill: rgb(180, 170, 170)!important;
}

//.swiper-button-prev {
//  color: gray !important;
//}
//
//.swiper-button-next {
//  color: gray !important;
//}

//.swiper {
//  position: static!important;
//}

//.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
//  bottom: -25px!important;
//}
//
//
:root {
 --swiper-navigation-size: 20px !important;
 --carousel-slider-max-width: 600px!important;
 --carousel-slider-width: 400px!important;
 --carousel-slider-height: 380px!important;
 --swiper-theme-color: #fff !important;
}

.swiper-pagination-bullet {
  margin: 0 6px 0 6px;
}

